import React from "react";
import {Pay} from './pay';
import {useLocation} from 'react-router-dom';

export const Bansko = (props) => {
    const {hash} = useLocation()

    setTimeout(() => {
        const element = document.getElementById(hash.slice(1))
        if(element) {
            element.scrollIntoView({behavior: "smooth"})
        }
    }, 300);

    return (
        <div id="snowpage" className="bansko">
            <div className="container">
                <div className="section-title">
                    <h2 className={"text-center"}>Ghete în Bansko</h2>
                </div>
                    <p>
                        În perioada <strong>22-27 februarie</strong> vrem să închidem sezonul cu un Snow Trip în stațiunea <a href="https://maps.app.goo.gl/injMu4L2dj9iSeS9A" target="_blank" rel="noopener noreferrer">Bansko</a> din Bulgaria.
                    </p>
                    <p>Stațiunea Bansko dispune de 75 km de pârtii, potrivite pentru toate nivelurile de dificultate (începători, intermediari, avansați). Cea mai lungă coborâre este 16 km de la vârful Todorka până în orașul Bansko, una dintre cele mai lungi coborâri din Europa de Est.
                    Domeniul dispunde de 14 instalații moderne, inclusiv telegondola principală care face legătura între oraș și stațiune. Bansko oferă de asemenea zone pentru ski off-piste
                    și un parc pentru cei pasionați de tricuri și sărituri.
                    </p>
                    <p>#DormiInGhete sau vii cu noi în Bansko?</p>
                {props && props.data && <div>
                    <div className="row">


                        <div className={"col-md-4 col-xs-12"}>
                            <div className="py-10">
                            <h3 className="">{props.data.included.title}</h3>
                            <div className="list-style">
                                <ul>

                                    {props.data.included.paragraphs.map((paragraph, idx) =>
                                        <li key={`include-${idx}`}>{paragraph}</li>)}
                                </ul>
                            </div>
                            </div>
                            <div className="py-10">
                                <h3 className="">{props.data.notIncluded.title}</h3>
                            <div className="list-style unchecked">
                                <ul>
                                    {props.data.notIncluded.paragraphs.map((paragraph, idx) =>
                                        <li key={`notinclude-${idx}`}>{paragraph}</li>)}
                                </ul>
                            </div>
                            </div>
                            </div>

                            <div className={"col-md-8 col-xs-12"}>

                            <img
                                title={props.data.groupImage[0]?.title}
                                alt={props.data.groupImage[0]?.title}
                                src={props.data.groupImage[0]?.img}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                            </div>
                        </div>

                    <div className="row d-flex align-center">
                        <div className={"col-md-4 col-xs-12"}>
                            <h3>{props.data.cost.title}</h3>
                            {props.data.cost.paragraphs.map((paragraph, idx) =>
                                <div dangerouslySetInnerHTML={{__html: paragraph}} key={`cost-${idx}`}/>)}
                        </div>

                        <div className={"col-md-8 col-xs-12"}>
                            <div className="text-center">
                                <div className="btn btn-reversed">
                                    <a href="https://forms.gle/ZvP1gdxfgFvyufaC9" rel="noreferrer" target="_blank">Formular înscriere</a>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="row d-flex -align-center">

                    <div className={"col-md-4 col-xs-12 "}>
                        <div className="py-10">
                            <h3>{props.data.accomodation.title}</h3>
                            {props.data.accomodation.paragraphs.map((paragraph, idx) =>
                                <p key={`accomodation-${idx}`} dangerouslySetInnerHTML={{ __html: paragraph }}></p>)}

                        </div>
                        </div>



                    <div className="col-md-8 col-xs-12">
                        <div className="row">
                        {props.data.images.map(
                            (image, index) =>
                                <div key={`img-${index}`} className="col-md-6 mt-10 col-xs-12">
                                    <img src={image} alt={"Cazare"} width="100%"/>
                                </div>
                        )}
                        </div>
                    </div>

                        <div className={"col-sm-12"} id={"payment"}>
                            <Pay details={"excursie snowboard"} />
                        </div>
                    </div>

                </div>}
            </div>
        </div>
    );
};
