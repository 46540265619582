import React from "react";
import {Pay} from './pay';
import {useLocation} from 'react-router-dom';

export const Snowboard = (props) => {
  const {hash} = useLocation()

  setTimeout(() => {
    const element = document.getElementById(hash.slice(1))
    if(element) {
      element.scrollIntoView({behavior: "smooth"})
    }
  }, 300);

  return (
    <div id="snowpage">
      <div className="container">
        <div className="section-title">
          <h2 className={"text-center"}>Tabere de snowboard</h2>
          <p>
          În perioadele <strong>22-27 ianuarie</strong> și <strong>5-10 februarie</strong> organizăm două tabere de snowboard în stațiunea  <a href="https://maps.app.goo.gl/6JtDBLjDaJfSmmWv5" target="_blank" rel="noopener noreferrer">Straja-Lupeni</a>, în colaborare cu Boarding Nation!
          </p>
          <p>Stațiunea Straja dispune de: 20km de pârtie, 4 telescaune și 5 teleski-uri, tunuri de zapadă pe toate pârtiile principale, instalație de nocturnă pe Pârtia Constantinescu.</p>
          <p>#DormiInGhete sau ești gata să te dai cu placa?</p>
        </div>
        {props && props.data && <div>
          <div className="row">
            <div className={"col-md-4 col-sm-12"}>
            <img
                title={props.data.image.title}
                alt={props.data.image.title}
                width="100%"
                src={props.data.image.img}
            />
            </div>
            <div className={"col-md-4 col-sm-12"}>
                  <h3 className="mt-0">{props.data.included.title}</h3>
              <div className="list-style">
                  <ul>

                  {props.data.included.paragraphs.map((paragraph, idx) =>
                      <li key={`include-${idx}`}>{paragraph}</li>)}
                    </ul>

                </div>
                </div>
              <div className={"col-md-4 col-sm-12"}>
                  <h3 className="mt-0">{props.data.notIncluded.title}</h3>
                <div className="list-style unchecked">
                  <ul>
                  {props.data.notIncluded.paragraphs.map((paragraph, idx) =>
                      <li key={`notinclude-${idx}`}>{paragraph}</li>)}
                  </ul>
                </div>
                </div>
              </div>



          <div className="row">

            <div className={"col-md-4 col-sm-12"}>
              <h3>{props.data.accomodation.title}</h3>
              {props.data.accomodation.paragraphs.map((paragraph, idx) =>
                  <p key={`accomodation-${idx}`}>{paragraph}</p>)}
            </div>
            <div className={"col-md-4 col-sm-12"}>
              <h3>{props.data.cost.title}</h3>
              {props.data.cost.paragraphs.map((paragraph, idx) =>
                  <div dangerouslySetInnerHTML={{__html: paragraph}} key={`cost-${idx}`}/>)}
            </div>
            {/* <div className={"col-md-4 col-sm-12"}>
              <h3>{props.data.equipment.title}</h3>
              {props.data.equipment.paragraphs.map((paragraph, idx) =>
                  <p key={`equipment-${idx}`}>{paragraph}</p>)}
            </div> */}

            <div className={"col-sm-12"}>
<div className="btn btn-reversed">
              <a href="https://forms.gle/4mTHcaL8BVdHQhi9A" rel="noreferrer" target="_blank">Formular înscriere</a>
            </div>

            </div>

            <div className={"col-sm-12"} id={"payment"}>
              <Pay details={"tabără ianuarie/februarie"} />
            </div>

            </div>
        </div>}
      </div>
    </div>
  );
};
