import React from 'react';

export const Pay = ({details}) => {

    return (
        <div className="pay">
           <h3>Plată prin transfer bancar:</h3>
            <ul>
                <li>În contul ING: <b>RO43 INGB 0000 9999 1676 7557</b></li>
            <li>Beneficiar: DORMI IN GHETE ADVENTURES S.R.L.</li>
            <li>Detalii plată: {details}</li>
                <br/>
            <li>Trimiteți dovada plății pe adresa de mail crew@dormiinghete.ro</li>
            </ul>
        </div>
    );
}