import React from "react";
import {Link} from 'react-router-dom';

export const Events = (props) => {
  return (
    <div>
      <div id="events">
        <div className="container">
            <div className="col-md-12 section-title">
                <h2>Evenimente viitoare</h2>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="events-card">
                        <h4>Tabere de snowboard</h4>
                        <p>Pregătim două tabere de snowboard în Straja cu instructaj, cazare și voie bună!</p>

                        <div className="button-container">
                            <Link to="/snowboard" className="btn btn-custom">Vreau în tabără</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="events-card">
                        <h4>Snow Trip în Bansko</h4>
                        <p>5 zile de pârtie în Bansko Bulgaria, ne ocupăm de cazare și transport.</p>
                        <div className="button-container">
                            <Link to="/bansko" className="btn btn-custom">Vreau în excursie</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
  );
};
